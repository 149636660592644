import axios from 'axios';
const uuidv4 = require('uuid/v4');
export default {
    data(){
        return {
            isUploadingInProgress: false,
            attachment_file:"",
            download_url:"",
            check_attachments: false,
        }
    },
    methods: {
        uploadCoverAttachment(newFile) {
            var input = newFile.target;
            if (input.files) {
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                let attachment = {
                    id: uuidv4,
                    name: input.files[0].name,
                    size: JSON.stringify(input.files[0].size),
                    source: source,
                    progress: 0,
                    created_time: new Date(),
                    error: ""
                }
                this.cover_attachment = attachment;
                this.getResumableURL(input.files[0], this.cover_attachment);
            }
        },
        uploadProfileAttachment(newFile) {
            var input = newFile.target;
            if (input.files[0]) {
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                let attachment = {
                    id: uuidv4,
                    name: input.files[0].name,
                    size: JSON.stringify(input.files[0].size),
                    source: source,
                    progress: 0,
                    created_time: new Date(),
                    error: ""
                }
                this.profile_attachment = attachment;
                this.getResumableURL(input.files[0], this.profile_attachment);
            }
        },
        getResumableURL(file, attachment) {
            this.isUploadingInProgress = true;
            let data = {
                file: attachment.name
            }
            data = new FormData()
            data.append('file',file)
            this.$http.post('https://cloudapis.digicollect.com/external/upload_file', data,{
                onUploadProgress: function (progressEvent) {
                    let progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    if (progress < 100) {
                        attachment.progress = progress;
                    }
                }.bind(this)
            })
            .then(response => {
                let resumable_url = response.data.url;
                attachment.url = resumable_url;
                this.isUploadingInProgress = false;
                attachment.progress = 100
                //this.uploadFileToUpload(resumable_url,file,attachment)
                if (response.data.hasOwnProperty('reason')){
                    attachment.error = "Error Uploading"
                    if (attachment.url == undefined) {
                        this.isUploadingInProgress = true;
                    } else {
                        this.isUploadingInProgress = false;
                    }
                }
            })
            .catch(error => {
                console.log(error.data.reason)
            })
        },
        // uploadFileToUpload(resumable_url, file, attachment) {
        //     this.$http.post("https://cloudapis.digicollect.com/external/upload_file", file, {
        //         onUploadProgress: function (progressEvent) {
        //             let progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        //             if (progress < 100) {
        //                 attachment.progress = progress;
        //             }
        //         }.bind(this)
        //     })
        //     .then(() => {
        //         attachment.progress = 100;
        //         attachment.uploading = false;
        //         this.isUploadingInProgress = false;
        //         delete attachment['source'];
        //     })
        //     .catch(error => {
        //         console.log(error.data.reason)
        //         this.error = "Error Uploading"
        //     })
        // },
    },
    computed:{
        error(){
            return ""
        }
    },
}
