<template>
    <div class="ml-2 mr-2 mb-10">
        <div v-if="ajax_call_in_progress" class="d-flex align-items-center justify-content-center">
            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
        </div>
        <div v-else class="pb-10 h-100 overflow-auto">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive">
                    <div >
                        <span class="pointer" @click="goToListView">Organization</span>
                        <div>Create Company</div>
                    </div>
                </div>
            </div>
            <div class="start-overflow">
                <div>
                    <!-- <div class="create-company-container w-100">
                        <h1 class="text-secondary fs-16 fw-600 text-center">Create Company</h1>
                        <div class="mt-5">
                            <div style="position: relative;">
                                <div v-if="cover_attachment.progress < 100" class="d-flex align-items-center justify-content-center" style="width: 100%; height: 200px; border-top-right-radius: 15px;border-top-left-radius: 15px;">
                                    <el-progress class="pr-0 mx-20" style="width: 100%;" :stroke-width="6" :percentage="cover_attachment.progress"></el-progress>
                                </div>
                                <img v-else class="ml-auto mr-auto" style="border-top-right-radius: 15px;border-top-left-radius: 15px; width: 100%;" :src="cover_attachment.url ? cover_attachment.url : company_info.cover_image" height="200px" />
                                <div class="change-pic px-5">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center" style="position: relative;">
                                            <div v-if="profile_attachment.progress < 100" class="d-flex align-items-center justify-content-center" style="width: 78px; height: 78px; border-radius: 50%; border: 2px solid #afafaf;">
                                                <el-progress :width="50" :stroke-width="3" type="circle" :percentage="profile_attachment.progress"></el-progress>
                                            </div>
                                            <img v-else style="border-radius: 50%;" width="78px" height="78px" :src="profile_attachment.url ? profile_attachment.url : company_info.logo" alt="name" class="profileImg">
                                            <div class="edit-image pointer">
                                                <label for="input_profile_pic" class="mb-0">
                                                    <img width="20" src="/static/images/image-edit-icon-new.svg" />
                                                </label>
                                                <input id="input_profile_pic" class="v-hidden w-0" type="file" name="input_profile_pic" @change="uploadProfileAttachment" accept="image/*" />
                                            </div>
                                        </div>
                                        <div>
                                            <div class="change-cover-pic d-flex align-items-center pointer">
                                                <i class="icon icon-picture mr-1 text-white" style="font-size: 20px;"></i>
                                                <label for="input_cover_pic" class="mb-0">
                                                    Change Picture
                                                </label>
                                                <input id="input_cover_pic" class="v-hidden w-0" type="file" name="input_cover_pic" @change="uploadCoverAttachment" accept="image/*" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="create-company-container w-100 pb-8">
                        <div class="create-company-header d-flex align-items-center justify-content-between" style="border-radius: 0 6px 0 0;">
                            <span class="fs-14 text-white fw-600 pl-3">Company Details</span>
                        </div>
                        <div class="create-company-header d-flex align-items-center justify-content-between mt-6 ml-6">
                            <span class="fs-14 text-white fw-500">Company Information</span>
                            <span class="pointer" @click="expandTabs('company_info')">
                                <i class="text-white" :class="{'icon-chevron-double-up':company_info_expand, 'icon-chevron-double-down':!company_info_expand}" style="font-size: 20px;"></i>
                            </span>
                        </div>
                        <div v-if="company_info_expand" class="px-10">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="d-flex align-items-center" style="position: relative;">
                                        <div v-if="profile_attachment.progress < 100" class="d-flex align-items-center justify-content-center" style="width: 78px; height: 78px; border-radius: 50%; border: 2px solid #afafaf;">
                                            <el-progress :width="50" :stroke-width="3" type="circle" :percentage="profile_attachment.progress"></el-progress>
                                        </div>
                                        <img v-else style="border-radius: 50%;" width="78px" height="78px" :src="profile_attachment.url ? profile_attachment.url : company_info.logo" alt="name" class="profileImg">
                                        <div class="edit-image pointer">
                                            <label for="input_profile_pic" class="mb-0">
                                                <img width="20" class="pointer" src="/static/images/editgreynew.svg"/>
                                            </label>
                                            <input id="input_profile_pic" class="v-hidden w-0 " type="file" name="input_profile_pic" @change="uploadProfileAttachment" accept="image/*" />
                                        </div>
                                        <div class="delete-image pointer" v-if="profile_attachment.url">
                                            <label class="mb-0" @click="deleteProfile">
                                                <i class="icon icon-delete pointer text-danger fs-12" style="background-color: #ecf1f9;border-radius: 50%;padding: 5px;"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100 position-relative">
                                        <span class="input-label">Company Name<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <input type="text" maxlength="100" class="input-field" v-validate="'required'" v-model="company_info.company_name" placeholder="Enter Company Name" name="company_name" />
                                            <span class="pointer" >
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <div class="text-right" style="position: absolute;right: 32px;">
                                            <span class="input-field-length">{{ company_info.company_name.length }}/100</span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('company_name')">
                                            {{errors.first('company_name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100 position-relative">
                                        <span class="input-label">Domain Name<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <input type="text" maxlength="30" class="input-field" @blur="domainExists(company_info.domain_name)" v-validate="'required|url'" v-model="company_info.domain_name" placeholder="Enter Domain Name" name="domain_name" />
                                            <span class="pointer" >
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <div class="text-right" style="position: absolute;right: 32px;">
                                            <span class="input-field-length">{{ company_info.domain_name.length }}/30</span>
                                        </div>
                                        
                                        <span class="invalid-feedback-form text-danger" v-if="domain_exists">
                                            Domain Name Already Exists.
                                        </span>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('domain_name')">
                                            {{errors.first('domain_name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100">
                                        <span class="input-label">Industry Type<span class="pl-1 text-danger">*</span>
                                                <!-- <sup>
                                                    <el-tooltip class="item" effect="dark"
                                                        placement="top-start" content="Info">
                                                        <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                    </el-tooltip>
                                                </sup> -->
                                        </span>
                                        <div class="d-flex align-items-center mt-2">
                                            <multiselect class="diginew-multiselect" name="industry_type" id="ind_type" v-validate="'required'" :show-labels="false" :internal-search="true" :max-height="200" :show-no-results="true" :searchable="true" :loading="loading" :options="industry_type_options" v-model="company_info.industry_type" placeholder="Select Industry Type">
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">List is Empty</div>
                                                </template>
                                                <template slot="afterList">
                                                    <div v-observe-visibility="reachedEndOfListDropdown" />
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                            </multiselect>
                                            <span class="pointer" @click="goToSettings('industry_type')">
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2" style="width:20px" alt="">
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('industry_type')">
                                            {{errors.first('industry_type')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100">
                                        <span class="input-label">Time Zone<span class="pl-1 text-danger">*</span>
                                                <!-- <sup>
                                                    <el-tooltip class="item" effect="dark"
                                                        placement="top-start" content="Info">
                                                        <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                    </el-tooltip>
                                                </sup> -->
                                        </span>
                                        <div class="d-flex align-items-start mt-2">
                                            <multiselect class="diginew-multiselect" name="time_zone" v-validate="'required'" label="value" :show-labels="false" :loading="loading" :searchable="true" :options="time_zone_options" v-model="company_info.time_zone" placeholder="Select Time Zone">
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">List is Empty</div>
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                            </multiselect>
                                            <span class="mt-2">
                                                <i class="icon icon-check-circle fs-30 ml-2" style="color: rgb(255, 154, 6);"></i>
                                            </span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('time_zone')">
                                            {{errors.first('time_zone')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100">
                                        <span class="input-label">Currency<span class="pl-1 text-danger">*</span>
                                                <!-- <sup>
                                                    <el-tooltip class="item" effect="dark"
                                                        placement="top-start" content="Info">
                                                        <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                    </el-tooltip>
                                                </sup> -->
                                        </span>
                                        <div class="d-flex align-items-center mt-2">
                                            <multiselect class="diginew-multiselect" label="label" v-validate="'required'" :show-labels="false" name="currency" :searchable="true" :options="currency_options" v-model="company_info.currency" placeholder="Select Currency">
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">List is Empty</div>
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                            </multiselect>
                                            <span class="mt-2">
                                                <i class="icon icon-check-circle fs-30 ml-2" style="color: rgb(255, 154, 6);"></i>
                                            </span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('currency')">
                                            {{errors.first('currency')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100">
                                        <span class="input-label">Number of Employees<span class="pl-1 text-danger">*</span>
                                            <!-- <sup>
                                                <el-tooltip class="item" effect="dark"
                                                    placement="top-start" content="Info">
                                                    <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                </el-tooltip>
                                            </sup> -->
                                        </span>
                                        <div class="d-flex align-items-center mt-2">
                                            <input type="text" maxlength="30" v-validate="'required|numeric'" class="input-field" v-model.number="company_info.no_of_employees" placeholder="Enter No of Employees" name="no_of_employees" />
                                            <span class="v-hidden" >
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2" style="width:25px" alt="">
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('no_of_employees')">
                                            {{errors.first('no_of_employees')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <span class="input-label">Info E-mail<span class="pl-1 text-danger">*</span></span>
                                    <div class="d-flex align-items-center position-relative">
                                        <div class="d-flex align-items-center mt-2 input-field w-100">
                                            <input type="text"
                                                class="form-control-email-dropdown w-100"
                                                v-model="company_info.info_email"
                                                name="primary_email" maxlength="30"
                                                v-validate="{required: true,regex: /^[a-zA-Z0-9-_.]+$/}"
                                                autocomplete="off"
                                                placeholder="Enter Info Email" />
                                            <span class="input-group-addon-email"
                                                style="font-size: 14px;">@</span>
                                            <span class="fs-14 pr-5" style="">
                                                {{company_info.domain_name.replace(/.+\/\/|www.|\+/g, '')}}
                                            </span>
                                        </div>
                                        <span class="" >
                                            <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                            </el-tooltip>
                                        </span>
                                        <div class="text-right" style="position: absolute;right: 32px; top: 50px;">
                                            <span class="input-field-length">{{ company_info.info_email.length }}/30</span>
                                        </div>
                                    </div>
                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('primary_email')">
                                        {{errors.first('primary_email')}}
                                    </span>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <span class="input-label">Primary Phone Number<span class="pl-1 text-danger">*</span></span>
                                    <div class="d-flex align-items-center mt-2">
                                        <div class="leftType ml-0">
                                            <div class="d-flex">
                                                <multiselect v-model="primary_phone_type"
                                                    class="diginew-multiselect phone-number pointer icon_space" id="ajax"
                                                    :options="primary_phone_type_options"
                                                    :multiple="false" :searchable="true" :internal-search="true"
                                                    :show-labels="false"
                                                    placeholder="" style="width: 70px !important; height: 38px !important;"
                                                    :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                    :max-height="200" :show-no-results="true" :hide-selected="false">
                                                    <template slot="singleLabel" slot-scope="props">
                                                        <img class="option__image" :src="props.option.image" :alt="props.option.name" style="width:22px">
                                                    </template>
                                                    <template slot="option" slot-scope="{ option }">
                                                        <div class="d-flex flex-row">
                                                            <img
                                                                :src="option.image"
                                                                style="width: 10px;" />
                                                            <span class="ml-4">
                                                                <span style="font-size: 14px">{{ option.name }}</span>
                                                            </span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="profile-primay-phone ml-2 w-100" style="position: relative;">
                                            <div>
                                                <vue-tel-input
                                                    name="primary_phone"
                                                    :enabledCountryCode="false"
                                                    :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                    class="w-100" ref="telInput"
                                                    placeholder="Enter Primary Phone Number"
                                                    :defaultCountry="company_info.primary_phone_number.country_code"
                                                    @onInput="compInfoPhone($event)"
                                                    data-vv-as="Alternate Phone"
                                                    v-validate="{required: true,min:10}"
                                                    v-model="company_info.primary_phone_number.phone_number">
                                                </vue-tel-input>
                                            </div>
                                        </div>
                                        <span class="pointer" >
                                            <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                            </el-tooltip>
                                        </span>
                                    </div>
                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('primary_phone')">
                                        {{errors.first('primary_phone')}}
                                    </span>
                                </div>
                            </div>
                            <div class="row mt-6">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <span class="input-label">About</span>
                                    <div class="mt-2">
                                        <textarea rows="2" type="text" name="about" maxlength="100" class="input-field input-textarea" v-model="company_info.about" placeholder="Enter text here" />
                                    </div>
                                    <div class="text-right" style="position: absolute;right: 32px;">
                                        <span class="input-field-length">{{ company_info.about.length }}/100</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="create-company-container w-100 pb-8">
                        <div class="create-company-header d-flex align-items-center justify-content-between ml-6">
                            <span class="fs-14 text-white">Business Unit</span>
                            <span class="pointer" @click="expandTabs('business_unit')">
                                <i class="text-white" :class="{'icon-chevron-double-up':business_unit_expand, 'icon-chevron-double-down':!business_unit_expand}" style="font-size: 20px;"></i>
                            </span>
                        </div>
                        <div v-if="business_unit_expand" class="px-10">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100 position-relative">
                                        <span class="input-label">Business Unit Name<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <input type="text" maxlength="100" v-validate="'required'" class="input-field" v-model="business_unit.business_unit_name" placeholder="Enter Business Unit Name" name="business_unit_name" />
                                            <span class="pointer" >
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <div class="text-right" style="position: absolute;right: 32px;">
                                            <span class="input-field-length">{{ business_unit.business_unit_name.length }}/100</span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('business_unit_name')">
                                            {{errors.first('business_unit_name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100">
                                        <span class="input-label">Country<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <multiselect class="diginew-multiselect" name="country" v-validate="'required'" :show-labels="false" :searchable="true" :options="country_options" v-model="business_unit.country" placeholder="Select Country">
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">List is Empty</div>
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                            </multiselect>
                                            <span class="pointer" >
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('country')">
                                            {{errors.first('country')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100">
                                        <span class="input-label">Started On (DD/MM/YYYY)<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <date-picker class="profile-date-picker profile-date-pickers calendar-edit-per input-field pt-0 pb-0 pr-0" v-model="business_unit.started_on" value-type="format" lang="en" :not-after="new Date()" type="date" 
                                            :clearable="false" style="padding-top: 5px !important; padding-left: 1px !important;"
                                            :editable="false"
                                            format="DD/MM/YYYY"></date-picker>
                                            <span class="pointer" >
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-if="bus_unit_started_on">
                                            Business unit started on is required.
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100 position-relative">
                                        <span class="input-label">Business Unit Code<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <input type="text" maxlength="100" class="input-field" v-validate="'required'" v-model="business_unit.business_unit_code" placeholder="Enter Business Unit Code" name="business_unit_code" />
                                            <span class="pointer" >
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <div class="text-right" style="position: absolute;right: 32px;">
                                            <span class="input-field-length">{{ business_unit.business_unit_code.length }}/100</span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('business_unit_code')">
                                            {{errors.first('business_unit_code')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="create-company-container w-100 pb-15">
                        <div class="create-company-header d-flex align-items-center justify-content-between ml-6">
                            <span class="fs-14 text-white">Department</span>
                            <span class="pointer" @click="expandTabs('department')">
                                <i class="text-white" :class="{'icon-chevron-double-up':department_expand, 'icon-chevron-double-down':!department_expand}" style="font-size: 20px;"></i>
                            </span>
                        </div>
                        <div v-if="department_expand" class="px-10">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100 position-relative">
                                        <span class="input-label">Department Name<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <input type="text" maxlength="30" class="input-field" v-validate="'required'" v-model="department.department_name" placeholder="Enter Department Name" name="department_name" />
                                            <span class="pointer" >
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <div class="text-right" style="position: absolute;right: 32px;">
                                            <span class="input-field-length">{{ department.department_name.length }}/30</span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('department_name')">
                                            {{errors.first('department_name')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100">
                                        <span class="input-label">Started On (DD/MM/YYYY)<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <date-picker class="profile-date-picker profile-date-pickers calendar-edit-per input-field pt-0 pb-0 pr-0" v-model="department.started_on" value-type="format" lang="en" :not-after="new Date()" type="date" 
                                            :clearable="false"
                                            :editable="false"
                                            format="DD/MM/YYYY" style="padding-top: 4px !important; padding-left: 1px !important;"></date-picker>
                                            <span class="pointer" >
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-if="depart_unit_started_on">
                                            Department unit started on is required.
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100 position-relative">
                                        <span class="input-label p-0">Primary Email<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <div class="d-flex align-items-center mt-2 input-field w-100">
                                                <input type="text"
                                                    class="form-control-email-dropdown w-100"
                                                    v-model="department.primary_email"
                                                    name="department_primary_email"
                                                    v-validate="{required: true,regex: /^[a-zA-Z0-9-_.]+$/}"
                                                    autocomplete="off"
                                                    maxlength="30"
                                                    placeholder="Enter Primary Email" />
                                                <span class="input-group-addon-email"
                                                    style="font-size: 14px;">@</span>
                                                <span class="fs-14" style="">
                                                    {{company_info.domain_name.replace(/.+\/\/|www.|\+/g, '')}}
                                                </span>
                                            </div>
                                            <span class="pointer" >
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <div class="text-right" style="position: absolute;right: 32px;">
                                            <span class="input-field-length">{{ department.primary_email.length }}/30</span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('department_primary_email')">
                                            {{errors.first('department_primary_email')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100">
                                        <span class="input-label">Business Unit<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <input type="text" maxlength="30" class="input-field" v-validate="'required'" :disabled="true" v-model="business_unit.business_unit_name" placeholder="Enter Business Unit" name="business_unit" />
                                            <span class="pointer" >
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <i class="icon icon-check-circle fs-30 mt-9 ml-2" style="color: #128807;"></i>
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('business_unit')">
                                            {{errors.first('business_unit')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                    <div class="w-100">
                                        <span class="input-label">Primary Phone Number<span class="pl-1 text-danger">*</span></span>
                                        <div class="d-flex align-items-center mt-2">
                                            <div class="leftType ml-0">
                                                <div class="d-flex">
                                                    <multiselect v-model="department_primary_phone_type"
                                                        class="diginew-multiselect phone-number pointer icon_space" id="ajax"
                                                        :options="primary_phone_type_options"
                                                        placeholder=""
                                                        :multiple="false" :searchable="true" :internal-search="true"
                                                        :show-labels="false" style="width: 70px !important; height: 38px !important;"
                                                        :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                        :max-height="100" :show-no-results="true" :hide-selected="false">
                                                        <template slot="singleLabel" slot-scope="props">
                                                            <img class="option__image" :src="props.option.image" :alt="props.option.name" style="width: 22px;">
                                                        </template>
                                                        <template slot="option" slot-scope="{ option }">
                                                            <div class="d-flex flex-row">
                                                                <img
                                                                    :src="option.image"
                                                                    style="width: 10px;" />
                                                                <span class="ml-4">
                                                                    <span style="font-size: 14px">{{ option.name }}</span>
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                            </div>
                                            <div class="profile-primay-phone ml-2 w-100" style="position: relative;">
                                                <div>
                                                    <vue-tel-input
                                                        name="department_primary_phone_number"
                                                        :enabledCountryCode="false"
                                                        :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                        class="w-100" ref="telInput"
                                                        :defaultCountry="department.primary_phone_number.country_code"
                                                        @onInput="departInfoPhone($event)"
                                                        placeholder="Enter Primary Phone Number"
                                                        data-vv-as="Alternate Phone"
                                                        v-validate="{required: true,min:10}"
                                                        v-model="department.primary_phone_number.phone_number">
                                                    </vue-tel-input>
                                                </div>
                                            </div>
                                            <span class="pointer" >
                                                <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('department_primary_phone_number')">
                                            {{errors.first('department_primary_phone_number')}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-15">
                            <div class="d-flex align-items-center justify-content-center">
                                <!-- <div v-if="edit_enable" class="d-flex align-items-center justify-content-center pb-8"> -->
                                    <button class="btn btn-outline-secondary" @click="cancel" :disabled="create_api_called" style="width: 120px;border-radius: 5px;">Cancel</button>
                                    <button class="btn btn-new-success ml-5" @click="createComp" :disabled="create_api_called" style="width: 120px;border-radius: 5px;">Save
                                        <hollow-dots-spinner v-if="create_api_called" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                    </button>
                                <!-- </div> -->
                                <!-- <button class="btn btn-new-cancel" style="background-color: #d51650;" @click="cancel">Cancel</button>
                                <button v-if="!create_api_called" class="btn btn-new-primary ml-5" @click="createComp">Save</button> -->
                                <!-- <div v-else class="ml-5" style="position: relative;">
                                    <button class="btn btn-new-primary" disabled>Saving...</button>
                                    <hollow-dots-spinner style="position: absolute;left: 35px;margin: 0;top: 25px;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#fff'" />
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-success v-if="create_success" :success_message="success_message" :succes_name="succes_name" @hideSuccess="hideSuccess" modal_name="create_success"></create-success>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
    </div>
</template>
<script>
import currencies from '../json/currency.json';
import countries from '../json/countries.json';
import timezone from '../json/timezone.json';
import companies from '../mixins/companies';
import uploadfile from '../mixins/uploadfile';
import {HalfCircleSpinner} from 'epic-spinners';
import { EventBus } from '../eventBus/nav-bar-event';
import { SweetModal } from 'sweet-modal-vue';
import axios from 'axios';
import globals from '../globals';
const CreateSuccess = () => import('./CreateSuccess');
export default {
    components: { CreateSuccess, HalfCircleSpinner, SweetModal },
    mixins: [companies, uploadfile],
    data() {
        return {
            create_api_called:false,
            business_unit_expand: true,
            company_info_expand: true,
            department_expand: true,
            domain_exists: false,
            bus_unit_started_on: false,
            depart_unit_started_on: false,
            create_api_called: false,
            cover_attachment: {},
            profile_attachment: {},
            temp_attachments:[],
            company_domain: [],
            accept: '*',
            extensions: '*',
            size: 1024 * 1024 * 10,
            multiple: true,
            directory: false,
            drop: true,
            dropDirectory: true,
            addIndex: false,
            thread: 3,
            name: 'file',
            postAction: '/upload/post',
            putAction: '/upload/put',
            headers: {
                'X-Csrf-Token': 'xxxx',
            },
            data: {
                '_csrf_token': 'xxxxxx',
            },
            autoCompress: 1024 * 1024,
            uploadAuto: false,
            isOption: false,
            loading: false,
            company_info: {
                cover_image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/coverpic3.jpg',
                logo: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png',
                company_name: '',
                domain_name: '',
                industry_type: '',
                time_zone: {},
                currency: currencies[1],
                no_of_employees: '',
                info_email: '',
                organization_name: '',
                organization_decription: '',
                primary_email: '',
                primary_phone_number: {
                    phone_type: 'Primary',
                    phone_number: '',
                    dial_code: '1',
                    country_code: 'US',
                },
                about: ''
            },
            business_unit: {
                business_unit_name: '',
                country: '',
                started_on: '',
                business_unit_code: ''
            },
            department: {
                department_name: '',
                primary_email: '',
                started_on: '',
                primary_phone_number: {
                    phone_type: 'Primary',
                    phone_number: '',
                    dial_code: '1',
                    country_code: 'US',
                },
            },
            create_success: false,
            success_message: '',
            succes_name: '',
            industry_type_options: [],
            department_primary_phone_type: {
                name: 'Work',
                image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
            },
            time_zone_options: timezone,
            // no_of_employees_options: [],
            currency_options: currencies,
            country_options: countries,
            publicDomain: false,
            primary_phone_type: {
                name: 'Work',
                image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
            },
            primary_phone_type_options: [
                {
                    name: 'Home',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/home-contact-icon.svg'
                },
                {
                    name: 'Mobile',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/mobile-contact-icon.svg'
                },
                {
                    name: 'Whatsapp',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/whatsapp-contact-icon.svg'
                },
                {
                    name: 'Line',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/line-contact-icon.svg'
                },
                {
                    name: 'Work',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                },
                {
                    name: 'Telegram',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/telegram-contact-icon.svg'
                },
                {
                    name: 'Others',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/others-contact-icon.svg'
                },
                {
                    name: 'Messenger',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/messenger-contact-icon.svg'
                },
                {
                    name: 'Fax',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/fax-contact-icon.svg'
                }
            ],
            warning_msg: '',
            ajax_call_in_progress: false,
            skip: 0,
            limit: 10,
            isVisible: false,
            count: 0,
            scrollable: false,
            temp_dropdown_options: [],
            new_token: ''
        }
    },
    // beforeRouteLeave (to, from, next) {
    //     if(to.path != `/CreateStandaloneCompany`){
    //         let message = "<div class='d-block'>Unsaved Company Data would be lost, Do you want to proceed?</div>";
    //         let options = {
    //             html: true,
    //             loader: false,
    //             reverse: false, // switch the button positions (left to right, and vise versa)
    //             okText: 'Yes',
    //             cancelText: 'No',
    //             animation: 'fade',
    //             type: 'basic',
    //             verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
    //             verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
    //             backdropClose: true,
    //         };
    //         this.$dialog.confirm(message, options)
    //         .then(function () {
    //             next();
    //         })
    //         .catch(function () {
    //             next(false);
    //         });
    //     } else {
    //         next()
    //     }
    // },
    methods: {
        deleteProfile(){
            this.profile_attachment = {}
        },
        hideSuccess() {
            this.create_success = false;
            if(this.$route.path.startsWith('/organization/createcompany')) {
                setTimeout(() => {
                    this.$router.push('/organization/listview');
                }, 500)
            } else {
                setTimeout(() => {
                    this.$router.push('/company/listview');
                }, 500)
            }
        },
        compInfoPhone({number,isValid,country}) {
            this.company_info.primary_phone_number.phone_number = number.national;
            this.company_info.primary_phone_number.dial_code = country.dialCode;
            this.company_info.primary_phone_number.country_code = country.iso2;
        },
        departInfoPhone({number,isValid,country}) {
            this.department.primary_phone_number.phone_number = number.national;
            this.department.primary_phone_number.dial_code = country.dialCode;
            this.department.primary_phone_number.country_code = country.iso2;
        },
        goToSettings(type) {
            switch(type) {
                case 'industry_type':
                    this.$router.push('/settings/general#industry_type');
                    break;
                default:
                    return;
            }
        },
        expandTabs(type) {
            if(type == 'company_info') {
                this.company_info_expand = !this.company_info_expand;
            } else if(type == 'business_unit') {
                this.business_unit_expand = !this.business_unit_expand;
            } else if(type == 'department') {
                this.department_expand = !this.department_expand;
            }
        },
        goToListView() {
            if(this.$route.path.startsWith('/organization/createcompany')) {
                this.$router.push('/organization/listview');
            } else {
                this.$router.push('/company/listview');
            }
        },
        cancel() {
            if(this.$route.path.startsWith('/organization/createcompany')) {
                this.$router.push('/organization/listview');
            } else {
                this.$router.push('/company/listview');
            }
        },
        setCookie(days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = "Token" + "=" + (this.new_token || "") + expires + "; path=/";
        },
        async createNewCompany() {
            this.create_api_called = true;
            let params = {
                business_unit_details: {
                    name: this.business_unit.business_unit_name,
                    started_on: this.business_unit.started_on.split('/')[2]+'-'+this.business_unit.started_on.split('/')[1]+'-'+this.business_unit.started_on.split('/')[0],
                    country: this.business_unit.country,
                    code: this.business_unit.business_unit_code,
                },
                department_details: {
                    primary_number: {
                        phone_type: "Primary",
                        phone_number: this.department.primary_phone_number.phone_number,
                        dial_code: this.department.primary_phone_number.dial_code,
                        country_code: this.department.primary_phone_number.country_code
                    },
                    name: this.department.department_name,
                    primary_email: this.department.primary_email+'@'+this.company_info.domain_name.replace(/.+\/\/|www.|\+/g, ''),
                    started_on: this.department.started_on.split('/')[2]+'-'+this.department.started_on.split('/')[1]+'-'+this.department.started_on.split('/')[0]
                },
                primary_number: {
                    phone_type: "Primary",
                    phone_number: this.company_info.primary_phone_number.phone_number,
                    dial_code: this.company_info.primary_phone_number.dial_code,
                    country_code: this.company_info.primary_phone_number.country_code
                },
                name: this.company_info.company_name,
                domain:  this.company_info.domain_name,
                industry_type:  this.company_info.industry_type,
                time_zone:  this.company_info.time_zone.value,
                no_of_employees: this.company_info.no_of_employees,
                currency: this.company_info.currency,
                info_email: this.company_info.info_email+'@'+this.company_info.domain_name.replace(/.+\/\/|www.|\+/g, ''),
                about: this.company_info.about,
                logo: this.profile_attachment.url ? this.profile_attachment.url : this.company_info.logo,
                cover_image: this.cover_attachment.url ? this.cover_attachment.url : this.company_info.cover_pic 
            }
            if(this.$route.path.startsWith('/organization/createcompany')) {
                params.organization_id = this.$route.params.id
            } else {
                params.is_standalone = true;
            }
            delete params.currency.name;
            await axios.post(globals.AUTH_SERVICE + "/company/", params).then((response) => {
                if(response.data.status_id == 1) {
                    EventBus.$emit('personal_info_data_update');
                    this.create_success = true;
                    this.success_message = "You have Successfully Created a Company";
                    this.succes_name = "Company Created!"
                    setTimeout(() => {
                        this.$modal.show('create_success');
                    }, 500);
                    if(response.data.token) {
                        this.new_token = response.data.token;
                        this.$cookie.delete("Token");
						this.$cookie.delete('Token', {
							domain: 'digicollect.com'
						});
                        setTimeout(() => {
                            this.setCookie(7);
                        }, 1000);
                    }
                }
            }).catch((err) => {
                this.create_api_called = false;
                EventBus.$emit('personal_info_data_update');
                this.warning_msg = err.response.data.reason;
                this.$refs.warning_modal.open();
                setTimeout(() => {
                    this.$refs.warning_modal.close();
                }, 3000);
            });
        },
        createComp() {
            this.$validator.validateAll().then(result => {
                if (result == true && this.business_unit.started_on && this.department.started_on && !this.domain_exists) {
                    this.bus_unit_started_on = false;
                    this.createNewCompany();
                } else {
                    if(this.business_unit.started_on == '' || this.business_unit.started_on == null || this.business_unit.started_on == undefined) {
                        this.bus_unit_started_on = true;
                    }
                    if(this.department.started_on == '' || this.department.started_on == null || this.department.started_on == undefined) {
                        this.depart_unit_started_on = true;
                    }
                }
            })
        },
        async reachedEndOfListDropdown(reached) {
            this.isVisible = reached;
            if (reached) {
                if (this.scrollable == false) {
                    this.loading = true;
                    let params = {
                        limit: 10,
                        type: 'industry_type',
                        skip: this.count
                    };
                    let response = await this.getDropdownsData(params);
                    this.temp_dropdown_options = response.response.length;;
                    if (response.skip == this.count) {
                        if (this.temp_dropdown_options > 0) {
                            let tempFiles = response.response;
                            tempFiles.forEach(el => {
                                this.industry_type_options.push(el);
                            });
                            this.count += response.response.length;
                            this.loading = false
                        } else {
                            this.scrollable = true
                            this.loading = false
                        }
                    }
                    this.loading = false
                }
            }
        },
        async domainExists(domain) {
            try {
                let params = {
                    domain: domain
                }
                let response = await this.checkCompDomainName(params);
                if (response.status_id == 1) {
                    if(response.exists == true) {
                        this.domain_exists = true;
                    } else {
                        this.domain_exists = false;
                    }
                }
            }
            catch (err) {
                this.domain_exists = false;
            }
        }
    },
    mounted() {
        if(this.loggedInUser.user_role == 'admin' || this.loggedInUser.user_role == 'employee') {
            this.$router.push('/company/listview');
        }
        let tz = new Date().getTimezoneOffset().toString();
        if (tz == "-330") {
            this.company_info.time_zone = {
                label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi (Asia/Kolkata)",
                value: "Asia/Kolkata",
            };
        } else if (tz == "-420") {
            this.company_info.time_zone = {
                label: "(GMT+07:00) Bangkok, Hanoi, Jakarta (Asia/Bangkok)",
                value: "Asia/Bangkok",
            };
        }
    },
    watch: {
        "business_unit.started_on": function(val) {
            if(val !== '') {
                this.bus_unit_started_on = false;
            } else {
                this.bus_unit_started_on = true;
            }
        },
        "department.started_on": function(val) {
            if(val !== '') {
                this.depart_unit_started_on = false;
            } else {
                this.depart_unit_started_on = true;
            }
        }
    },
    created() {
        const dict = {
            custom: {
                company_name:{
                    required:() => "Company name is required"
                },
                domain_name:{
                    required:() => "Domain name is required",
                    url:() => "Enter vaid url.",
                },
                industry_type:{
                    required:() => "Industry type is required"
                },
                time_zone:{
                    required:() => "Time zone is required"
                },
                currency:{
                    required:() => "Currency is required"
                },
                no_of_employees:{
                    required:() => "No of employees is required",
                    numeric:() => "Enter valid number",
                },
                primary_email:{
                    required:() => "Primary email is required"
                },
                primary_phone:{
                    required:() => "Primary phone is required"
                },
                business_unit_name:{
                    required:() => "Business unit name is required"
                },
                country:{
                    required:() => "Country is required"
                },
                department_name:{
                    required:() => "Department name is required"
                },
                department_primary_email:{
                    required:() => "Primary email is required",
                    regex:() => "Enter valid email"
                },
                business_unit:{
                    required:() => "Business name is required"
                },
                business_unit_code:{
                    required:() => "Business unit code is required"
                },
                department_primary_phone_number:{
                    required:() => "Primary phone is required"
                },
            }
        } 
        this.$validator.localize("en", dict);       
    },
    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser;
        }
    },
}
</script>
<style scoped>
    .create-company-container {
        background-color: #fff;
        padding: 0px 0 0px 0;
    }
    .create-company-container:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    .create-company-container:nth-child(2) {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    .create-company-container:last-child {
        /* border-bottom-left-radius: 6px; */
        border-bottom-right-radius: 6px;
    }
    .overflow-auto {
        overflow: auto;
    }
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }
    .input-field {
        border-radius: 8px !important;
        border: solid 0.5px #3f3f3f;
        background-color: #fff;
        padding: 8px 10px;
        width: 100%;
        height: 39px;
    }
    .input-field-length {
        opacity: 0.5;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.3px;
        color: #000;
    }
    .btn-new-cancel {
        color: #fff;
        background-color: #d51650;
        border-radius: 5px;
        padding-left: 35px;
        padding-right: 35px;
        text-transform: none;
        font-weight: 500;
        border: 2px solid #d51650;
        line-height: 30px;
        box-shadow: none;
    }
    .edit-image {
        position: absolute;
        /* right: 0; */
        bottom: 5px;
        left: 60px;
    }
    .delete-image {
        position: absolute;
        /* right: 0; */
        bottom: 4px;
        left: 0px;
    }
    .diginew-multiselect {
        padding: 7.5px 10px !important;
    }
    .input-border, .vue-tel-input {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }
    .mx-input {
		display: inline-block;
		width: 100%;
		height: 35px !important;
		padding: 6px 30px;
	}
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .profile-date-pickers {
        padding: 5px 10px !important;
    }
    .mx-input-wrapper {
        padding: 4px 0px !important;
    }
</style>